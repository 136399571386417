<template>
        <div class="h-[32px] flex items-center justify-center exo bg-[#6F38A5] text-white font-[800] sm:text-base text-sm !z-40"> 
            <span>Centre for <span class='text-primary-orange'>Applied</span> Machine Learning and Data Science</span>
            
        </div>
        
        <nav class="!fixed !top-5 z-50 bg-white h-[48px] lg:h-[66px] sm:max-w-[604px] max-w-[367px] lg:max-w-[972px] w-full border-primary-purple border rounded-[100px] mx-auto shadow-lg px-[26px] inset-0 sm:px-[52px] mt-8 flex justify-between items-center">
      <div>
          <router-link to="/">
          <img src="../../assets/camlds-logo.png" alt="logo" class="sm:w-40 w-20" />
        </router-link>
    </div>
    
    <div id="nav" class="hidden lg:flex justify-between items-center xl:space-x-[24px] text-primary-purple space-x-6  text-base font-[500] cursor-pointer">
        <!-- <a href="https://camlds.org/#about" class="nav-link">About</a> -->
        <a :href="`${$router}/#about`" class="nav-link">About</a>
        <router-link to="/blog" class="nav-link">Blog</router-link>
        <router-link to="/course" class="nav-link">Courses</router-link>
        <router-link to="/contact" class="nav-link">Contact</router-link>
        
        <!-- <a  @click="showModal = !showModal" class="nav-link">Contact</a> -->
      </div>
      <router-link to="/enroll" class="hidden lg:block border border-primary-border rounded-[50px] font-semibold py-2 px-4 text-primary-purple hover:bg-primary-pink cursor-pointer">
        Show Interest
      </router-link>
      
      <div class="lg:hidden">
        <button @click="toggleMenu" class="focus:outline-none">
          <i class="fas fa-bars text-2xl"></i>
        </button>
      </div>
    

    <div v-if="isMenuOpen" class="fixed inset-0 bg-white flex flex-col items-center text-center justify-center z-40">
      <button @click="closeMenu" class="absolute top-4 right-4 text-3xl">
        &times;
      </button>
      <div class="flex flex-col space-y-8 text-base font-[500px]  cursor-pointer hover:text-primary-purple">
        <a @click="closeMenu" :href="`${$router}/#about`" class="nav-link">About</a>
        <router-link to="/blog" class="">Blog</router-link>
        <router-link to="/course" class="">Courses</router-link>
        <router-link to="/contact" class="">Contact</router-link>
        <!-- <a  @click="(()=>{closeMenu();showModal = !showModal; })" class="">Contact</a> -->
        <!-- <a @click="closeMenu" href="#projects">Project</a> -->
        <router-link to="/enroll"  @click="closeMenu" class=" border border-primary-border rounded-[50px] py-2 px-6 text-primary-border hover:bg-primary-pink cursor-pointer">
          Enroll Now
        </router-link>
    </div>
    </div>
  </nav>
  <Contact v-if="showModal"  />
</template>

<script setup lang="ts">
import Contact from '@/components/ModalComp/Contact.vue';
import {ref} from 'vue'

const $router = window.location.origin;

const showModal = ref(false);
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

</script>

<style scoped>
.nav-link{
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; 
  width: 0;
  height: 2px; 
  background-color: #581699;
  transition: width 0.3s ease-in-out; 
}

.nav-link:hover::after {
  width: 100%;
}
</style>