<template>
  <transition name="fade">
    <div v-if="isVisible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-hidden z-50">
      <div class="bg-white text-center rounded-lg max-w-screen-lg max-h-screen overflow-hidden flex flex-col  z-50 m-8">
        <div class="flex items-center justify-between rounded-t-lg p-4 border-b-2 border-gray-300 bg-white sticky top-0">
          <h5 class="exo font-medium font-base">Enroll Now</h5>
          <button type="button" class="text-gray-500 hover:text-gray-700" @click="closeModal">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>

        <!-- Ads Body -->
        <div class="flex flex-col-reverse lg:flex-row items-center justify-between gap-4 sm:gap-12 p-2 md:p-4 w-full h-full overflow-auto">

          <!-- Text Content -->
          <div class="flex flex-col items-center justify-center lg:w-1/2 p-4">
            <h2 class="text-2xl md:text-4xl text-[#3B0545] font-semibold text-center mb-4">Start your Tech Journey with real-world projects.</h2>
            <p class="text-center mb-4">...and achieve your learning goals. With our expert tutors, your goals are closer than ever!</p>
            <router-link to="/course" class="bg-primary-purple text-white px-6 py-3 rounded hover:bg-primary-pink block">
              Start Learning now!
            </router-link> 
          </div>

          <!-- Carousel -->
          <div class="flex items-center justify-center lg:w-1/2 w-full sm:mx-0 mx-2 overflow-x-hidden">
            <Carousel :value="courses" :numVisible="numVisible" :numScroll="1" circular :autoplayInterval="interval" @mouseenter="interval = 15000" @mouseleave="interval = 3000">
              <template #item="slotProps">
                <div class="course-item h-full w-full px-2 sm:px-4 py-1">
                  <img :src="slotProps.data.image" :alt="slotProps.data.title" class="min-h-[320px] sm:min-h-[520px] w-full rounded-lg shadow-md" />
                </div>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue';
import Carousel from 'primevue/carousel';

const numVisible = 1;
const interval = ref(4000);

const isVisible = ref(true);
const closeModal = () => {
  isVisible.value = false;
};

const courses = ref([
  {
    title: 'Fullstack',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316482/1_ytgefr.png',
  },
  {
    title: 'Gen AI',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316486/9_ygieqv.png',
  },
  
  {
    title: 'frontend',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316482/2_vtdjjb.png',
  },
  {
    title: 'Ui/ux',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316482/4_gqti1s.png',
  },
  {
    title: 'linux',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316483/5_cd6i6k.png',
  },
  {
    title: 'Python',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316483/3_xajtxv.png',
  },
  {
    title: 'html',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316483/6_sirow8.png',
  },
  {
    title: 'Django',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316484/7_agr5fp.png',
  },
  {
    title: 'Database',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316485/8_wfxrp7.png',
  },
  {
    title: 'low code',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316486/11_vb9qsq.png',
  },
  {
    title: 'Data Science',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1720685800/Courses-Fliers-website_1_mj3pwz.jpg',
  },
  {
    title: 'low code/ no code',
    image: 'https://res.cloudinary.com/dp0whcqar/image/upload/v1719316486/10_kuwp7m.png',
  },
]);
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
