<template>
  <section id="courses" class="bg-[#faf9ff] py-28">
    <div class="max-w-[95vw] sm:max-w-[754px] xl:max-w-[1280px] mx-auto lg:px-0 px-4">
      <div class="title text-black text-center">
        Individual
        <vue-typewriter-effect
          :strings="['Programmes','Courses']"
          class="text-primary-purple"
        />
      </div>

      <hr class="w-1/2 mx-auto" />

      <!-- =============+++++++++++++++++++++++++++++++++++++++++++++============================================ -->
      <div class="mt-8 h-full py-3">
        <Carousel :value="courses" :numVisible="numVisible" :numScroll="1" :autoplayInterval="interval" @mouseenter="interval = 15000" @mouseleave="interval = 3000">
          <template #item="slotProps">
            <a :href="`/course/${slotProps.data.id}`" class="course-item block">
              <img :src="slotProps.data.image" :alt="slotProps.data.title" class="w-full h-48 rounded-t-lg object-cover" />
              <div class="mt-4 px-3">
                <h3 class="text-sm font-semibold h-[60px]  text-left  ">{{ slotProps.data.title }}</h3>
                <div class="flex justify-start gap-4 items-center">
                  <p class="text-gray-500 mt-2"><i class="pi pi-clock" style="font-size:0.75rem"></i> {{ slotProps.data.duration }}</p>
                  <p class="text-gray-500 mt-2 flex justify-start items-center"> <LevelBarIconComp :type='slotProps.data.level' /> {{ slotProps.data.level }}</p>
                </div>
                <p class="text-gray-500 mt-2 text-left"><i class="pi pi-briefcase" style="font-size: 0.75rem"></i> {{ slotProps.data.projects }} Projects</p>
                <p class="text-primary-orange mt-2 text-left "><i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-fill" style="font-size: 0.75rem"></i> <i class="pi pi-star-half-fill" style="font-size: 0.75rem"></i> </p>
                <hr />
                <a :href="`/course/${slotProps.data.id}`" class="flex items-center justify-end">
                  <div class="flex justify-start items-center gap-2 py-2 px-4 w-fit my-3  bg-[#EDEAFF] text-purple-950 font-bold text-sm rounded-md">
                    <div class="">View Details</div>
                    <i class="pi pi-arrow-right" style="font-size: 0.5rem"></i>
                  </div>
                </a>
              </div>
            </a>
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import LevelBarIconComp from '../../components/IconComp/LevelBarIconComp.vue';
import VueTypewriterEffect from "vue-typewriter-effect";
import Carousel from 'primevue/carousel';
import { CourseApi } from '@/services';

interface Course {
  id: string;
  category: string[];
  image: string;
  title: string;
  duration: string;
  level: string;
  projects: string;
  rating: string;
}


const courses =ref<Course[]>([]);

onMounted(async () => {
  try {
    const response = await CourseApi.allCourses(); // Replace with your API endpoint
    courses.value =  response
    console.log('object', courses.value);
    // Update courses data with fetched data
  } catch (error) {
    console.error('Error fetching courses:', error);
    // Handle error gracefully, e.g., show an error message
  }
});

const numVisible = ref(4);
const interval = ref(3000);

onMounted(() => {
  updateNumVisible();
});

// Update numVisible based on screen size
const updateNumVisible = () => {
  if (window.innerWidth < 468) {
    numVisible.value = 1;
  } else if (window.innerWidth >= 768 && window.innerWidth < 1023) {
    numVisible.value = 2;
  } else {
    numVisible.value = 4;
  }
};
updateNumVisible();
// Watch for window resize and update numVisible
watch(() => window.innerWidth, updateNumVisible);
updateNumVisible();

</script>

<style scoped>
.course-item {
  margin: 10px;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  padding: 0 2px 3px 0;
  width: 284px;
}
.course-item:hover {
  transform: scale(1.05);
}

@media (max-width: 767px) {
   .course-item {
    width: 294px;
    margin: 4px;
  }
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content {
    display: flex !important;
  }
  .p-carousel .p-carousel-viewport .p-carousel-viewport-content .p-carousel-item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
 
}
</style>
